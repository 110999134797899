var tag = document.createElement("script");
tag.src = "https://www.youtube.com/player_api";

var firstScriptTag = document.getElementsByTagName("script")[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

window.onYouTubeIframeAPIReady = function () {
	var player;
	if(document.getElementById("YouTubeBackgroundVideoPlayer")) {
		player = new YT.Player('YouTubeBackgroundVideoPlayer', {
			videoId: document.getElementById("YouTubeBackgroundVideoPlayer").dataset.videoid, // YouTube Video ID
			width: 1280,               // Player width (in px)
			height: 720,              // Player height (in px)
			playerVars: {
				autoplay: 1,        // Auto-play the video on load
				autohide: 1,
				disablekb: 1,
				controls: 0,        // Hide pause/play buttons in player
				showinfo: 0,        // Hide the video title
				modestbranding: 1,  // Hide the Youtube Logo
				loop: 1,            // Run the video in a loop
				fs: 0,              // Hide the full screen button
				rel: 0,
				enablejsapi: 1
			},
			events: {
				onReady: function (e) {
					e.target.mute();
					e.target.setPlaybackQuality('hd1080');
				},
				onStateChange: function (e) {
					if (e && e.data === 1) {
						console.log(e);
						var videoHolder = document.querySelector('.hero-container--video');
						if (videoHolder) {
							videoHolder.classList.remove('loading');
						}
					} else if (e && e.data === 0) {
						e.target.playVideo()
					}
				}
			}
		});
	}
}