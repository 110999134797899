import { tabs } from './tabs/tabs';
import MmenuLight from 'mmenu-light';
import './hero/videoHero';

import './main.scss';

console.log('webpack says hello');

function init() {
	tabs();
	document.addEventListener(
		"DOMContentLoaded", () => {
			const menu = new MmenuLight(
				document.querySelector("#my-menu"),
				"(max-width: 1024px)"
			);

			console.log('menu?', menu);

			const navigator = menu.navigation();
			const drawer = menu.offcanvas();

			var menuButton = document.querySelector("a[href='#my-menu']");

			menuButton.addEventListener("click", (evnt) => {
				evnt.preventDefault();

				var opened = document.body.classList.contains('mm-ocd-opened');

				if (opened) {
					menuButton.classList.remove('active');
					drawer.close();
				} else {
					menuButton.classList.add('active')
					drawer.open();
				}
					
			});
		}
	);
}

init();