export function tabs() {
	let tabContainer = document.querySelectorAll('.tab-section');
	let pageTitles = document.querySelectorAll('div[data-pagetitle]');

	if (!tabContainer) {
		return;
	}

	tabContainer.forEach(function (tc, i, arr) {
		let tabButtons = tc.querySelectorAll('.tab-button');
		let tabContent = tc.querySelectorAll('.tab-content');
		const activeTabButton = tc.querySelectorAll('.tab-button.active');
		let hasActiveTabButton = activeTabButton.length > 0;

		//initial setup
		tabContent.forEach((tCt, i) => {
			if (i == 0 && !hasActiveTabButton) {
				tCt.classList.add('show');
			}
		});

		//initial setup
		pageTitles.forEach((pt, i) => {
			if (i == 0  && !hasActiveTabButton) {
				pt.classList.add('show');
			}
		});

		tabButtons.forEach((tb, i) => {
			if (i == 0 && !hasActiveTabButton) {
				tb.classList.add('active');
			}

			tb.addEventListener('click', function () {

				let currentTabData = tc.querySelector('.tab-content[data-tabcontent="' + this.dataset.tabid + '"]');
				let currentPageTitle = document.querySelector('div[data-pagetitle="' + this.dataset.tabid + '"]');

				if(currentTabData){
					// remove classess
					if(tc.querySelector('.tab-content.show')) {
						tc.querySelector('.tab-content.show').classList.remove('show');
					}

					if(tc.querySelector('.tab-button.active')) {
						tc.querySelector('.tab-button.active').classList.remove('active');
					}

					if(document.querySelector('.hero-background.show')) {
						document.querySelector('.hero-background.show').classList.remove('show');
					}

					// add classes
					currentTabData.classList.add('show');
					this.classList.add('active');

					if(currentPageTitle) {
						currentPageTitle.classList.add('show');
					}
				}
				
			});
		});
	});
}